.not_found_page_div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 10vh;
    width: 100%;
    margin-top: 50px;
    text-align: center;
}

.not_found_page_div_home_link{
    width: 100%;
    margin: auto;
    min-height: 20vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.not_found_page_div_link{
    text-decoration: none;
    color: white;
}