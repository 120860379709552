.local_pin{
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.upcoming_league_card_name{
     white-space: nowrap;
    overflow: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
}