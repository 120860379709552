.slider {
  margin: 0 auto;
  margin-top: 15px;
  width: 95%;
  min-height: 400px;
  min-width: 500px;
  padding-right: 50px;
}

.image {
  width: 100%;
  height: 100%;
  /* min-height: 1000px; */
  min-width: 500px;
  max-height: 800px !important; 
  object-fit: contain;
  margin: auto;
  backdrop-filter: blur(4px);
}

.slider_sm {
  margin: 0 auto;
  margin-top: 30px;
  width: 95%;
  min-width: 500px;
  padding-right: 50px;
}

.image_sm {
  width: 100%;
  height: 100%;
  min-width: 500px;
  min-height: 60vh;
  max-height: 60vh !important; 
  object-fit: contain;
  margin: auto;
  backdrop-filter: blur(4px);
}

.right-arrow {
  position: absolute;
  top: 45%;
  right: 60px;
  font-size: 3rem;
  z-index: 10;
  cursor: pointer;
}

.left-arrow {
  position: absolute;
  top: 45%;
  left: 60px;
  font-size: 3rem;
  z-index: 10;
  cursor: pointer;
}

.arrow_div_sm{
  position: absolute;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 41%;
  left: 7%;
  top: 45%;
}

.right-arrow_sm {
  /* position: absolute; */
  /* top: 45%; */
  /* right: 60px; */
  font-size: 3rem;
  z-index: 10;
  cursor: pointer;
  /* left: 45%; */
}

.left-arrow_sm {
  /* position: absolute; */
  /* top: 45%; */
  /* left: 60px; */
  font-size: 3rem;
  /* left: 6%; */
  z-index: 10;
  cursor: pointer;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
  height: 600px;
}

.slide_sm {
  opacity: 0;
  transition-duration: 1s ease;
}

.active_sm.slide_sm{
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

@media (max-width : 1140px) {

  .event_details_main_modal{
    margin: unset;
    margin-left: 3.5%;
  }

.slider {
  margin: 0 auto;
  margin-top: 30px;
  width: 95%;
  min-height: 400px;
  min-width: 500px;
  padding-right: 0px;
}

.slider_sm {
  width: 100%;
  margin-top: 0px;
  min-height: 500px;
}

.image {
  width: 100%;
  height: 100%;
  max-height: 400px;
  min-height: 400px;
  min-width: 400px;
  object-fit: contain;
  margin: auto;
  backdrop-filter: blur(4px);
}

.image_sm {
  width: 100%;
  height: 100%;
  min-width: 400px;
  min-height: 500px;
  max-height: 500px !important;
  object-fit: contain;
  margin: auto;
  backdrop-filter: blur(4px);
}

.arrow_div_sm{
  width: 60%;
  left: 20%;
  top: 40%;
}

}

@media (max-width : 552px) {
    
.slider {
  margin: 0 auto;
  margin-top: 30px;
  width: 95%;
  min-height: 100px;
  min-width: 100px;
  padding-right: 0px;
}

.image {
  width: 100%;
  height: 100%;
  max-height: 300px;
  min-height: 300px;
  min-width: 100px;
  object-fit: contain;
  margin: auto;
  backdrop-filter: blur(4px);
}


.slider_sm {
  margin: 0 auto;
  margin-top: 30px;
  width: 95%;
  min-height: 100px;
  min-width: 100px;
  padding-right: 0px;
}

.image_sm {
 width: 100%;
  height: 100%;
  max-height: 300px !important;
  min-height: 300px !important;
  min-width: 100px;
  object-fit: contain;
  margin: auto;
  backdrop-filter: blur(4px);
}

.arrow_div_sm{
  width: 60%;
  left: 20%;
  top: 30%;
}

.slide.active {
  height: 300px;
}

}