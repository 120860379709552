
.lighter_breadcrumb{
    color: rgb(190, 190, 190);
    font-size: 14px;
}

.upcoming_leagues_grid{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    width: 80%;
    max-width: 1200px;
    margin: auto;
    margin-top: 20px;
    grid-gap: 10px;
}

@media (max-width:1337px) {
    .upcoming_leagues_grid{
    grid-template-columns: repeat(3,1fr);
    }
}

@media (max-width:995px) {
    .upcoming_leagues_grid{
    grid-template-columns: repeat(2,1fr);
    }
}


@media (max-width:677px) {
    .upcoming_leagues_grid{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
}