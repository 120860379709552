
.event_details_venue_amenities p{
    font-size: 14px;
    font-weight: 500;
}
.organizer_flex_div{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.organizer_flex_div_left{
    width: 50%;
}

.sport_card_div{
    width: 40px;
}

.organizer_flex_div_right{
    width: 50%;
}

.organizer_description{
    font-size: 12px;
    color: rgb(80, 80, 80);
    line-height: 20px;
}

.organizer_contact_info_flex_colum{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.organizer_contact_info_flex_list{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin: 8px;
}

.organizer_contact_info_flex_list_header{
    font-size: 12px;
    color: rgb(78, 78, 78);
    display: block;
}

.organizer_contact_info_flex_list_detail{
    font-size: 14px;
}

.no_decoration_a_href{
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.event_details_flex_row{
    display: flex;
    width: 95%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 8px;
}

.heart_div{
    width:20px;
    height:20px;
    padding:6px;
    border-radius : 50%;
    background-color:black;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
}


.sport_type_card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    margin: 5px;
    margin-right: 20px;
    min-width: 50px;
    font-size: 14px;
}

.loader_div{
     display : flex;
     flex-direction: row;
     height:400px;
     justify-content:center;
     align-items : center;
}