.App {
  text-align: center;  
}

::-webkit-scrollbar {
  height: 4px;              /* height of horizontal scrollbar ← You're missing this */
  width: 4px;               /* width of vertical scrollbar */
  border: 1px solid transparent;
}

::-webkit-scrollbar-thumb:horizontal{
        background: transparent;
        height: 2px;
        border-radius: 10px;
    }

::-webkit-scrollbar-thumb:vertical{
        background: black;
        height: 1px;
        border-radius: 10px;
    }

::-webkit-scrollbar-track:vertical {
  background: transparent
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.router_link{
    text-decoration: none;
    color: white;
}