.modal_close {
    position: relative;
    top: 0;
    left: 95%;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

@media (max-width:500px) {
    .modal_close{
    left: 5%;
    }
}