.location_flex_div {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 700;
}

.location_title {
    font-size: 30px;
    font-weight: 700;
    margin-left: 5px;
}

.card_icons {
    width: 20px;
    height: 20px;
}

.hompage_subcomponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.hompage_subcomponent_category {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding-top: 30px;
    padding-bottom: 0px;
}

.hompage_subcomponent_nearby {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding-bottom: 50px;
}

.hompage_subcomponent_grey {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    background-color: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
}

.hompage_subcomponent_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    max-width: 1200px;
    margin: auto;
    align-items: center;
}

.hompage_subcomponent_heading {
    font-weight: 400;
    font-size: 1rem;
}

.sports_nearby_grid {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    max-width: 1200px;
    min-height: 300px;
}

.sports_nearby_grid_card {
    min-height: 300px;
    min-width: 300px;
    position: relative;
    z-index: 99;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px;
    padding-left: 10px;
    backdrop-filter: blur(4px);

}

.miles_in_card {
    background-color: rgba(0, 0, 0, 0.897);
    color: #fff;
    position: absolute;
    top: 20;
    z-index: 999;
    margin-top: 5px;
    margin-left: 10px;
    left: 0;
    font-size: 16px;
    width: max-content;
    padding: 3px;
    display: flex;
    align-items: center;
    border-radius: 5px;
}

.local_pin {
    width: 20px;
    height: 20px;
}

.featuring_in_card {
    background-color: red;
    color: #fff;
    position: relative;
    top: 0;
    z-index: 99;
    margin-top: -50px;
    margin-left: auto;
    left: 0;
    font-size: 16px;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    max-width: fit-content;
}

.card_event_details {
    position: relative;
    bottom: -196px;
    left: 0;
    color: #fff;
    font-weight: 600;
}

.card_details_date {
    font-size: 14px;
    padding: 0;
    margin: 0;
}

.card_details_event {
    font-size: 20px;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
}

.card_details_location {
    font-size: 14px;
    padding: 0;
    margin: 0;
}

.view_all_link {
    font-weight: 700;
    color: rgb(255, 208, 0);
    cursor: pointer;
}

.nodecoration_color_link {
    text-decoration: none;
    color: rgb(255, 208, 0);
}

.sports_category_grid_owl {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-height: 300px;
    max-width: 1200px;
    overflow-x: scroll;
    overflow-y: hidden;
    min-height: 200px;
}

.sports_category_grid_dots {
    margin-top: 20px;
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    max-width: 1200px;
    max-height: 330px;
    overflow-x: scroll;
    overflow-y: hidden;
    min-height: 200px;
}

.sports_category_grid {
    margin-top: 20px;
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    max-width: 1200px;
    max-height: 320px;
    overflow-x: scroll;
    overflow-y: hidden;
    min-height: 200px;
}

.pickup_grid {
    margin-top: 20px;
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    max-width: 1200px;
    max-height: 160px;
    overflow-x: scroll;
    overflow-y: hidden;
}

.sports_category_grid_card_img {
    width: 50%;
    max-height: 120px;
    max-width: 100px !important;
}

.owl-carousel .nav-btn {
    height: 47px;
    position: absolute;
    width: 26px;
    cursor: pointer;
    top: 85px !important;
}

/* .owl-carousel .owl-stage-outer{
    width: 95%;
    margin-left: 2.5%;
} */

.owl-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.owl-prev {
    font-size: 40px !important;
}

.owl-carousel .prev-slide {
    background-image: url("../../Images/left-arrow.png");
    background-size: contain;
    left: 0px;
    height: 30px;
    width: 30px;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    background-repeat: no-repeat;
    background-position: center;
}

.owl-carousel .prev-slide-fees {
    background-image: url("../../Images/left-arrow.png");
    background-size: contain;
    left: 0px;
    height: 20px;
    width: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-repeat: no-repeat;
    background-position: center;
    top: 55px !important;
}

.owl-carousel .next-slide {
    background-image: url("../../Images/right-arrow.png");
    background-size: contain;
    right: 0px;
    height: 30px;
    width: 30px;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    background-repeat: no-repeat;
    background-position: center;
}

.owl-carousel .next-slide-fees {
    background-image: url("../../Images/right-arrow.png");
    background-size: contain;
    right: 0px;
    height: 20px;
    width: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-repeat: no-repeat;
    background-position: center;
    top: 55px !important;
}

.owl-next {
    font-size: 40px !important;
}

.owl-carousel .owl-item img {
    width: auto !important;
}

.owl-dots {
    display: flex;
    justify-content: center;
    height: 20px;
    margin-top: 10px;
}

.owl-dot {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 2px;
    border: 1px solid #ccc !important;
}

.owl-dots .active {
    border: 1px solid rgb(255, 208, 0) !important;
    background: rgb(255, 208, 0) !important;
}

.sports_category_grid_card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-width: 170px;
    min-height: 180px;
    border-radius: 8px !important;
    margin: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.no_events_available_div {
    min-height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
}

.sports_category_title_div {
    display: flex;
    flex-direction: column;
}

.upcoming_league_card_main {
    /* max-width: 300px; */
    height: 300px;
    background-repeat: no-repeat;
    border-radius: 8px;
    margin: 0px;
    position: relative;
}

.upcoming_league_card {
    width: 100%;
    height: 80%;
    max-height: 200px;
    min-height: 100px;
    border-radius: 8px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-color: rgba(0, 0, 0, 0.708);
    background-size: cover;
}

.sports_category_title {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 15px;
    font-weight: 600;
}

.upcoming_league_card_date {
    color: red;
    font-size: 16px;
}

.upcoming_league_card_name {
    font-size: 18px;
    color: black;
    font-weight: 600;
    display: block;
}

.upcoming_league_card_club {
    display: inline-block;
    color: rgb(80, 78, 78);
    font-size: 14px;
}

.pickup_card_img {
    max-height: 160px;
    min-height: 160px;
    object-fit: contain;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(4px);
}

.pickup_card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 320px;
    position: relative;
}

.pickup_cover {
    width: 160px;
    height: 160px;
    border-radius: 8px;
    object-fit: cover;
    margin: auto;
    backdrop-filter: blur(4px);
}

.upcoming_cover {
    width: 100%;
    height: 200px;
    border-radius: 8px;
    object-fit: cover;
    margin: auto;
    backdrop-filter: blur(4px);
}

.pickup_details {
    width: 160px;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
}

.pickup_red {
    display: inline-block;
    color: red;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
}

.pickup_name {
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
}

.pickup_charge {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
}


.pickup_distance {
    display: inline-block;
    font-size: 14px;
    margin: 3px;
    color: #ccc;
    font-weight: 600;
}

.dynamic_link_img_div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.dynamic_link_img {
    width: 150px;
}

.dynamic_link_div {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.dynamic_link {
    font-size: 14px;
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;

}

.share_float_button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 35px;
    height: 35px;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.604);
    border-radius: 50%;
    margin-left: 8px;
    margin-top: 8px;
    cursor: pointer;
    z-index: 999;
}

.share_float_button_right {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 35px;
    height: 35px;
    top: 0;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.604);
    border-radius: 50%;
    margin-left: 8px;
    margin-top: 8px;
    cursor: pointer;
    z-index: 999;
}

.share_float_button_right_sm{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 35px;
    height: 35px;
    top: 0;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.604);
    border-radius: 50%;
    margin-left: 8px;
    margin-top: -10px;
    cursor: pointer;
    z-index: 999;
}

.no_decoration_link_card {
    text-decoration: none;
    color: black;
    border-radius: 8px;
    width: 95%;
    margin: auto;
}

.no_decoration_link_href {
    text-decoration: none;
    color: black;
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    margin-top: 10px;
}

@media (max-width:1128px) {
    .sports_category_grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width:996px) {
    .pickup_grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width:671px) {
    .upcoming_league_card_main {
        width: 100%;
        max-width: 400px;
        margin: auto;
    }
}

@media (max-width:660px) {
    .pickup_grid {
        grid-template-columns: repeat(1, 1fr);
        width: 97%;
    }
}


@media (max-width:847px) {
    .sports_category_grid {
        grid-template-columns: repeat(2, 1fr);
    }
}


@media (max-width:568px) {
    .sports_category_grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .location_title {
        font-size: 20px;
        font-weight: 700;
        margin-left: 5px;
    }
}

@media (max-width: 900px) {

    .pickup_card {
        width: 100%;
        max-width: 400px;
        margin: auto;
    }
}

@media (max-width: 320px) {
    .pickup_red {
        font-size: 12px;
    }

    .pickup_name {
        font-size: 16px;
    }

    .pickup_charge {
        font-size: 12px;
    }

    .pickup_card_img {
        max-height: 120px;
        min-height: 120px;
    }

    .pickup_card {
        width: 300px;
    }

    .pickup_cover {
        width: 120px;
        height: 120px;
    }

    .pickup_details {
        width: 120px;
        height: 120px;
        display: flex;
        margin-left: 20px;
    }


}