.auth_form_link{
    margin-top: 20px;
    font-weight: 600;
}

.auth_link{
    color: #FAA61C;
    cursor: pointer;
}

.auth_form_div{
    margin-top: 20px;
}

.google_autocomplete_div{
    border : 1px solid #ccc;
    margin-top: 20px;
    border-radius: 5px;
}

.login_textfield_div{
    margin: auto;
    margin-top: 20px;
}

.login_textfield_div_two{
    margin: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.terms_condition_Link{
    color: #FAA61C;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
}

.auth_button{
    border: none;
    outline: none;
    margin: auto;
    margin-top: 5px;
    width: 100%;
    background-color: #FAA61C;
    color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: 0px 8px 18px -8px rgba(250, 166, 28, 0.9);
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    font-weight: 600;
    font-size: 16px;
    max-height: 48.5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 500px;
}

.auth_button:hover{
     background-color: #dc921a;
     transition: all 0.01s ease-in-out;
}

.auth_link_orange{
    font-size: 14px;
    color: #FAA61C;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    font-weight: 600;
    cursor: pointer;

}

.divider{
    width: 100%;
    margin: auto;
    height: 1.5px;
    background-color: #ccc;
    margin-bottom: 20px;
}

.divider_fees{
     width: 100%;
    margin: auto;
    height: 1.5px;
    background-color: #ccc;
}

.divider_with_spacing{
    width: 100%;
    margin: auto;
    height: 1.5px;
    background-color: #ccc;
    margin-bottom: 10px;
    margin-top: 10px;
}

.social_links_div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.social_div_heading{
    font-weight: 600;
    font-size: 15px;
    color: #696969;
}

.social_div_buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.social_button{
    width: 45%;
    background-color: #EFF2FC;
    color: black;
    outline: none;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center ;
    cursor: pointer;
}

.resend_otp_div{
    width: 100%;
    text-align: right;
    margin-top: 15px;
    margin-bottom: 15px;
    color:rgba(250, 166, 28, 0.9);
}
.resend_otp_div span{
    cursor: pointer;
}

@media (max-width:400px) {
    .auth_form_link{
    font-size: small;
}
}