.searchmap_background_img{
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
}


.searchmap_search_results_div{
    width: 80%;
    max-width: 1200px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.search_map_white_div{
      padding-top: 30px;
    background-color: #fff;
    padding-bottom: 30px;
}

.search_map_grey_div{
    padding-top: 30px;
    background-color: rgba(233, 233, 233, 0.65);
    padding-bottom: 30px;
}

.searchmap_search_results_subdiv{
     width: 80%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.searchmap_search_results{
    font-size: 30px;
    font-weight: 600;
    margin-top: 15px;
}

.searchmap_search_results_orange{
     font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
    color: rgb(248, 108, 58);
}



.searchmap_search_box{
    width: 80%;
    margin: auto;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(39, 39, 39);
    border-radius: 15px;
    min-height: 140px;
    display: flex;
    justify-content: space-evenly;
    margin-top: -45px ;
    align-items: center;
    max-width: 1200px;
        padding-left: 2.5%;
    padding-right: 2.5%;
}

.searchmap_search_box_flex_wrap{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.searchmap_formcontrol{
    width: 45%;
}

.searchmap_search_box_flex_wrap_sub{
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.searchmap_feeds_body{
    width: 100%;
    margin: auto;
}

.searchmap_grid{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    width: 80%;
    max-width: 1200px;
    margin: auto;
    margin-top: 10px;
    grid-gap: 15px;
}

.searchmap_grid_sm{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    width: 80%;
    max-width: 1200px;
    margin: auto;
    margin-top: 20px;
    grid-gap: 15px;
}

.searchmap_heading_lists{
    font-size: 24px;
    font-weight: bold;
    padding: 0px;
    margin: 0px;
}

@media (max-width:1171px) {
    .searchmap_grid{
    grid-template-columns: repeat(2,1fr);
    }
    .searchmap_grid_sm{
    grid-template-columns: repeat(2,1fr);
    }
}

@media (max-width:766px) {
    .searchmap_grid{
    grid-template-columns: repeat(1,1fr);
    }
    .searchmap_grid_sm{
    grid-template-columns: repeat(1,1fr);
    width: 95%;
    }
}


@media (max-width:677px) {
    .searchmap_grid{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    }
}


@media (max-width: 932px) {
    .searchmap_search_box_flex_wrap{
        width: 95%;
    flex-direction: column;
}
.searchmap_search_box_flex_wrap_sub{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}
.searchmap_search_box{
    width: 90%;
}

}

@media (max-width: 500px){
    .searchmap_search_box{
    display: none;
}

.bg_event_payment_details{
    padding-top: 15%;
    width: 100%;
    margin: auto;
    padding: 0;
}
.credit_card_heading{
    width: 80%;
}
.creditcards_flex_div {
    margin: auto;
    display: flex;
    width: 80%;
    justify-content: space-evenly;
    align-items: center;
}
}


@media (max-width: 432px){
.searchmap_search_results{
    font-size:24px;
}

.searchmap_heading_lists{
    font-size: 24px;
    font-weight: bold;
}

.searchmap_search_results_orange{
     font-size: 12px;
}
}