.circular_spinner_div{
    min-height: 200px;
    min-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.div_center{
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
}

.login_textfield_div_flex{
    width: 100%;
    display: flex;
    margin: auto;
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.google_autocomplete{
     width:80%;
     height: 32px;
     border-radius:4px;
     border:none;
     padding:7.5px 14px;
     font-size:16px;
}

.google_autocomplete:focus{
    outline-width: 2px;
    outline-color: #f5c242;
}

div.pac-container {
    z-index: 99999999999 !important;
}
.notification_flex_div{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notification_flex_div p {
    font-weight: 600;
}