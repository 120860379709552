
.appbar{
    display: none;
}

.appbar_div{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

@media (max-width : 782px) {
.appbar{
    position: fixed;
    z-index: 999;
    bottom: 0;
    width: 100%;
    display: block;
    background-color: white;
    padding-bottom: 15px;
    padding-top: 15px;
}

.appbar_icon{
    width: 25px;
    height: 25px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}   

.app_tab{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #fff;
}

.active_tab {
    color: #FAA61C;
}

.inactive_tab{
    color: #fff;
}

}
