.main_div_leagues {
    padding-bottom: 20px;
}

.upcoming_leagues_background_img{
    background-image: url("../Images/leaguesCover.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    min-height: calc(100vw/3.954);
}

.leagues_details{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    color:white;
    margin-top: calc(60vw/3.954);
    padding-bottom: 50px;
}

.breadcrumb{
    color: white;
    font-size: 16px;
}
.lighter_breadcrumb{
    color: rgb(190, 190, 190);
    font-size: 14px;
}

.upcoming_leagues_grid{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    width: 80%;
    max-width: 1200px;
    margin: auto;
    margin-top: 20px;
    grid-gap: 10px;
}

.pickup_page_grid{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    width: 80%;
    max-width: 1200px;
    margin: auto;
    margin-top: 20px;
    grid-gap: 10px;
}

@media (max-width:1337px) {
    .upcoming_leagues_grid{
    grid-template-columns: repeat(3,1fr);
    }
.pickup_page_grid{
    grid-template-columns: repeat(2,1fr);
}
}

@media (max-width:995px) {
    .upcoming_leagues_background_img{
    background-size: contain;
}
    .upcoming_leagues_grid{
    grid-template-columns: repeat(2,1fr);
    }
    .pickup_page_grid{
    width: 90%;
    grid-template-columns: repeat(2,1fr);
}
}

@media (max-width:834px) {
  
.leagues_details{
    margin-top: 15%;
    padding-bottom: 80px;
}
}


@media (max-width:677px) {
    .upcoming_leagues_grid{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
    .pickup_page_grid{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
}

@media (max-width: 500px) {
    
.leagues_details{
    align-items: inherit;
    flex-direction: column;
    padding-bottom: 0px;
}
.breadcrumb{
    color: white;
    margin-top: 0px;
    font-size: 13px;
    text-align: right;
}
.upcoming_leagues_background_img{
    padding-bottom: 0px;
    margin-top: 58px;
}
}

@media (max-width:360px) {
  
.leagues_details{
    margin-top: 10%;
}
}