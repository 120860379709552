.event_details_main_body{
    padding-top: 55px;
    background-color: #E9E9E9;
    min-height: 100vh;
    width: 100%;
    margin: auto;
}

.event_details_venue_description_div{
    width: 100%;
}

.share_icon_cursor{
    color: #E074B2;
    margin-right: 10px;
    cursor: pointer;
}

.event_details_venue_description_title{
    font-size: 14px;
}

.event_details_venue_description{
    font-size: 12px;
}

.pdf_link{
    text-decoration: none;
    color: #dc921a;
}

.event_details_main_flex{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 60vh;
}

.event_details_venue_amenities_flex_modal{
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
}

.filter_png_img{
    width: 25px;
    height: 25px;
    filter: invert(78%) sepia(36%) saturate(3908%) hue-rotate(287deg) brightness(93%) contrast(88%);
}

.filter_png_img_gender{
    height: 25px;
    object-fit: scale-down;
    width: 25px;

}

.filter_png_img_xs{
    width: 10px;
    height: 10px;
    margin-right: 2px;
    filter: invert(78%) sepia(36%) saturate(3908%) hue-rotate(287deg) brightness(93%) contrast(88%);
}

.icon_color_with_spacing{
    color: #E074B2;
    height: 56px;
    cursor: pointer;
    width: 56px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-self: flex-end;
}

.icon_lg{
    width: 40px;
    height: 40px;
}

.nav_icon_whistle{
    width: 30px;
    height: 25px;
}

.fees_plan_div{
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    max-height: 30px;
}


.price_desc_div_clamped{
   white-space: nowrap;
    overflow: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 600px;
    margin-top: -16px;
    font-size: 14px;
    margin-bottom: 16px;

}

.event_details_main{
    width: 95%;
    margin: auto;
}

.event_details_heading{
    font-size: 16px !important;
    font-weight: 600;
}

.venue_select_control_flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 600px;
}


.event_details_main_modal{
    width: 50%;
    margin: auto;
    margin-left: 3.5%;
    max-height: 400px;
}

.event_details_main_modal_name{
    margin-top: 50px;
    width: 50%;
}

.event_details_rule_flex_div{
    width: 90%;
    max-width: fit-content;
    display: flex;
    flex-direction: column;
}

.ticket_price_div{
    min-width: 295px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #ccc;
}

.register_button{
    border: none;
    outline: none;
    margin: auto;
    margin-top: 5px;
    width: 100%;
    background-color: #FAA61C;
    color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: 0px 8px 18px -8px rgba(250, 166, 28, 0.9);
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    font-weight: 600;
    font-size: 16px;
    max-height: 48.5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.register_button:hover{
     background-color: #dc921a;
     transition: all 0.01s ease-in-out;
}



.event_organizer_profile_pic{
     width: 30px;
     height: 30px;
     border-radius: 50%;
     margin-right: 10px;
     object-fit: contain;
}

.event_details_flex_carousel{
    min-height: 400px;
    display: flex;
    flex-direction: row;
    max-width: 2000px;
    margin: auto;
    overflow: hidden;
    align-items: center;
    padding-bottom: 40px;
}

.carousel_div{
    height: 100%;
    width: 50%;
    min-height: 400px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.event_details_div{
    height: 100%;
    width: 50%;
    min-height: 400px;
    padding-top: 40px;

}

.event_detail_heading{
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.event_detail_organizer{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    margin-top: 10px;
}

.event_details_date{
    font-weight: 500;
    font-style: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
}

.register_button_div_md{
    max-width: 600px;
}

.event_details_date_title{
    margin-left: 15px;
     white-space: nowrap;
    overflow: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    font-size: 18px;
}

.event_details_date_subtitle{
    color: rgb(139, 137, 137) !important;
    font-size: 15px;
     white-space: nowrap;
    overflow: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
}

.event_details_date_time{
    font-size: 14px;
    color: rgb(135, 135, 135);
    margin-left: 15px;
}

.event_details_location{
    font-weight: 500;
    font-style: 18px;
    display: flex;
    max-width: 600px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.event_details_location_flex{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.event_details_location_right{
    width: 40%;
    display: flex;
    justify-content: center;
    text-decoration: none;
    margin-left: 10px;

}

.event_details_location_button{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
    width: 150px;
    border: 1px solid #FAA61C;
    color: #FAA61C;
    border-radius: 8px;
    cursor: pointer;
}

.location_bubble{
    color: #FAA61C ;
}



.event_details_location_button:hover {
    background-color: #FAA61C;
    color: white;
    transition: all 0.2s ease-in-out;
}

.event_details_location_button:hover > .location_bubble{
    color : #fff;
    transition: all 0.2s ease-in-out;
}

.event_details_venue_amenities p{
    font-size: 18px;
    font-weight: 500;
}

.event_details_venue_amenities_flex{
    display: flex;
    flex-direction: row;
    width: 80%;
    margin-bottom: 15px;
    flex-wrap: wrap;
}

.icon_color{
    color: #E074B2;
    margin-right: 10px;
}

.icon_color_0pad{
    color: #E074B2;
    margin-right: 10px;
}

.icon_color_img{
   width: 25px;
   height: 25px;
   margin-right:10px;
}

.icon_color_lg{
    color: #E074B2;
    margin-right: 10px;
    font-size: 40px !important;
}

.event_details_venue_amenities_flex_card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 13px;
    margin-bottom: 8px ;
    font-size: .8rem;
}

.event_details_league_flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
}

.event_details_league_card{
    width: 100%;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #ccc;
    justify-content: left;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.silent_category{
    display: block;
    font-weight: 300;
    color: rgb(115, 115, 115);
    font-size: 10px;
}

.icon_img{
    max-width: 30px;
    margin-right: 6px;
}

.subheading_league_card{
    font-size: 12px;
}

.event_details_game_info_flex{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    max-width: 600px;
    margin-top: 15px;
    margin-bottom: 20px;
}

.event_details_rules_flex_sub_div{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    max-width: 600px;
    margin-top: 15px;
    margin-bottom: 20px;
}

.event_details_rules_flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin-top: 15px;
    max-height: 200px;
    overflow-y: scroll;
    max-width: 600px;
    margin-bottom: 20px;
}

.event_details_rules_card{
    width: 100%;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    max-width: 258px;
    width: max-content;
    padding: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-left: 15px;
    align-items: center;
    border: 1px solid #ccc;
}

.event_details_rules_card:first-child{
    margin-left: 0px;
}

.event_details_fees_card{
    width: 40%;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    max-width: 200px;
    padding: 8px;
    margin-right: 15px;
    justify-content: space-evenly;
    align-items: center;
}

.event_details_fees_card_right{
    display: flex;
    flex-direction: column;
}

.event_details_fees_card_heading{
    font-weight: 600;
    font-size: 18px;
}

@media (max-width : 1140px) {
    
.event_details_flex_carousel{
    min-height: 400px;
    display: flex;
    flex-direction: column;
}

.event_detail_heading{
    margin-top: 20px;
}

.event_details_main_flex{
    flex-direction: column;
    min-height: 72vh;
}

.event_details_main_modal{
    width: 93%;
    margin: auto;
    margin-left: 3.5%;
    max-height: 400px;
}

.event_details_main_modal_name{
    margin-top: 50px;
    width: 100%;
}

.carousel_div{
    width: 100%;
    min-height: 500px;
}

.event_details_div{
    width: 95%;
    margin: auto;
    min-height: 500px;
    padding-top: 30px;
}


.event_details_location_right{
    width: 50%;
}

.event_details_venue_amenities{
    max-width: 600px;
}

.event_details_venue_amenities_flex{
    width: 100%;
    flex-wrap: wrap;
}

.event_details_rules_flex{
    width: 100%;
    max-width: 600px;
}

.event_details_league_flex{
    max-width: 1120px;
    width : 100%;
    justify-content: space-between;
}
}

@media (max-width:820px){
    .event_details_main_modal{
    width: 93%;
}
}

@media (max-width : 552px) {
    
.carousel_div{
    min-height: 100px;
}

.event_details_location{
    flex-direction: column;
    align-items: flex-start;
}

.icon_color_0pad{
    margin-right: 0px;
}

.event_details_location_button{
    width: 100%;
    justify-content: center;
}

.event_details_location_right{
    margin-top: 15px;
    width: 100%;
    margin-bottom: 15px;
}

.divider{
    margin: auto !important;
    margin-top: 5px;
}

.register_button_div_md{
    max-width: 552px;
}
}

@media (max-width: 565px){
    .event_details_rule_flex_div{
    flex-direction: row;
    align-items: center;
    justify-items: center;
}

.event_details_rule_flex_div{
    max-width: 100%;
}

.event_details_location_right{
    margin-left: 0px;
}

.event_details_rules_card:first-child{
    margin-left: 25px;
}

.event_details_league_flex{
    width: 40%;
    flex-direction: column;
}
.event_details_league_card{
    width: 100%;
    height: 158px;
    flex-direction: column;
    margin-top: -12px;
    text-align: center;
}


.event_details_rules_card{
    width: 100%;
    margin: auto;
    margin-left: 25px;
    margin-bottom: 5px;
}
.event_details_game_info_flex{
    flex-direction: column;
}

.event_details_fees_card{
    width: 90%;
    margin: auto;
    margin-bottom: 10px;
    max-width: 552px;
    margin-right: 5px;
}

}

@media (max-width: 400px) {
    .event_details_rules_flex{
    flex-direction: row;
}
.ticket_price_div{
    min-width: 300px;
}
}