.pin {
    width: 40px;
    height: 40px;
    border-radius: 50% 50% 50% 0;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
}

.scroll_events_hovered_div{
 display: flex;
  flex-direction: column;
  justify-content: space-between;
   position: absolute;
   overflow-y: scroll;
   max-height: 150px;
   z-index: 9999999;
}
.icon_xs {
    color: #E074B2; 
    font-size: 9px !important;
}

.marker_div_event_detail{
    width: 350px;
    display: flex;
    height: 125px;
    background-color: white;
    z-index: 9999999;
    text-decoration: none;
}

.marker_div_event_detail_img{
    width: 125px;
    height: 125px;
    border-radius: 8px;
    object-fit: cover;
    margin: auto;
    backdrop-filter: blur(4px);
}

.marker_div_event_detail_text{
    margin-left: 10px;
    text-decoration: none;
    color : black;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.marker_div_event_detail_heading{
   font-size: 20px;
   font-weight: 700;
}

.marker_img {
    text-align: center;
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50%;
    padding: 2px;
    top: 50%;
    margin: -15px 0 0 -16px;
}

.pin:after {
    content: "";
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    position: absolute;
    border-radius: 50%;
}

.default_pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
}

.default_pin:after{
    content: "";
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #e6e6e6;
    position: absolute;
    border-radius: 50%;
}

.bounce {
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
}

.img_bounce {
    animation-name: img_bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
}

.pulse {
    background: #d6d4d4;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    transform: rotateX(55deg);
    z-index: -2;
}

.pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    opacity: 0;
    box-shadow: 0 0 6px 15px rgb(255, 154, 46);
    animation-delay: 1.1s;
}

@keyframes pulsate {
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

@keyframes img_bounce {
    0% {
        opacity: 0;
        transform: translateY(-2000px);
    }

    60% {
        opacity: 1;
        transform: translateY(30px);
    }

    80% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes bounce {
    0% {
        opacity: 0;
        transform: translateY(-2000px) rotate(-45deg);
    }

    60% {
        opacity: 1;
        transform: translateY(30px) rotate(-45deg);
    }

    80% {
        transform: translateY(-10px) rotate(-45deg);
    }

    100% {
        transform: translateY(0) rotate(-45deg);
    }
}