.homepage_background_video{
    padding-top: 50px ;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 40px;
}

.homepage_img{
    width: 100%;
    object-fit: contain;
    backdrop-filter: blur(4px);
}

.homepage_background_img{
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: flex-end;
    min-height: 500px;
    background-size: cover;
}

.search_box_mobile_view_button {
    display: none !important;
}

.spinner_center_div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.bg_event_details{
    color: white;
    display: flex;
    justify-content: space-evenly;
    background-size: cover;
    align-items: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    margin: auto;
    object-fit: contain;
    background-position: center;
    backdrop-filter: blur(4px);
}

.bg_details{
    width: 60%;
    text-align: center;
    margin-bottom: 25px;
}

.bg_event_date_details{
    font-size: 2rem;
}

.bg_event_header{
    margin-top: 0.25rem;
    font-weight: 700;
    font-size: 3rem;
}

.bg_event_desc{
    margin-top: 0.50rem;
    font-size: 1.1rem;
    text-shadow:0 0 10px #000;
}

.bg_event_league_temp{
    margin-top: 1.25rem;
    font-size: 1.5rem;
}

.bg_event_payment_details{
    padding-top: 15%;
    width: 35%;
    padding-left: 2.5%;
    padding-right: 2.5%;
}

.creditcards_flex_div{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.credit_card_logo{
    width: 100%;
    height: 100%;
}

.credit_card_heading{
    font-size: 1.2rem;
}

.credit_card_logo_div{
    width: 8%;
    height: 8%;
    padding: 1.2%;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 10px;
}

.bg_event_payment_details a {
    color: #fff;
}

.homepage_search_box_mobile {
    width: 100%;
    margin: auto;
    background-color: #fff;
    min-height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: -45px;
    align-items: center;
    max-width: 1200px;
    margin-top: 20px;
}

.homepage_search_box_flex_wrap_mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.homepage_search_box_flex_wrap_sub_mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.homepage_formcontrol_mobile {
    width: 100%;
    margin-top: 15px;
}

.search_button_mobile {
    width: 50%;
    background-color: black;
    color: white;
    height: 53px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 15px;
}


.homepage_search_box{
    width: 80%;
    margin: auto;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(39, 39, 39);
    border-radius: 15px;
    min-height: 140px;
    display: flex;
    position: relative;
    justify-content: space-evenly;
    margin-top: -60px ;
    align-items: center;
    max-width: 1200px;
        padding-left: 2.5%;
    padding-right: 2.5%;
}

.homepage_search_box_flex_wrap{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.homepage_formcontrol{
    width: 45%;
    padding-left: 5px;
    padding-right: 5px;

}

.homepage_search_box_flex_wrap_sub{
    width: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.homepage_feeds_body{
    width: 100%;
    margin: auto;
}

@media (max-width: 932px) {
    .homepage_search_box_flex_wrap{
        width: 95%;
    flex-direction: column;
}
.homepage_search_box_flex_wrap_sub{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}
.homepage_search_box{
    width: 90%;
}
.bg_event_date_details{
    font-size: 1.5rem;
}
}


@media (max-width:1626px){
    .homepage_background_img{
        min-height: calc(100vw/3.96);
        padding-top: 9px;
    }
    .bg_event_details{
        background-size: contain;
    }
}

@media (max-width: 1265px){
     .bg_event_header{
        font-size: 30px;
    }
    .bg_event_desc{
        font-size: 18px;
    }
}

@media (max-width: 732px) {
     .bg_event_header{
        font-size: 20px;
    }
    .bg_event_desc{
        font-size: 12px;
    }
    .bg_event_date_details{
    font-size: 1.2rem;
}
}

@media (max-width: 500px){
    .search_box_mobile_view_button {
        display: block !important;
        position: absolute;
       top: 15px;
    }
    .bg_event_header{
        font-size: 24px;
    }
    .bg_details{
        margin-top: 25% !important;
    }
    .bg_event_desc{
        font-size: 16px;
    }
    .homepage_background_img{
        padding-bottom: 0px;
        padding-top: 12px;
    }
     .bg_event_details{
        background-size: cover;
        height: 350px;
    }
    .homepage_background_video{
        padding-bottom: 5px;
    }
    .homepage_search_box{
    display: none;
}
.bg_event_details{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 0%;
}
.bg_details{
    margin: auto;
    margin-top: 50px;
    width: 90%;
    text-align: center;
}
.bg_event_payment_details{
    padding-top: 15%;
    width: 100%;
    margin: auto;
    padding: 0;
}
.credit_card_heading{
    width: 80%;
}
.creditcards_flex_div {
    margin: auto;
    display: flex;
    width: 80%;
    justify-content: space-evenly;
    align-items: center;
}
.bg_event_date_details{
    font-size: 1.2rem;
}
}


.search_button{
    width: 100%;
    background-color: black;
    color: white;
    height: 53px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    cursor: pointer;
}

.remove_filter_btn{
    width: 40%;
    background-color: red;
    color: white;
    height: 53px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    cursor: pointer;
    margin-left: 5px;
}