.footer{
    min-height: 300px;
    background-color: black;
    color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.no_decoration_link{
    text-decoration: none;
      color: rgb(255, 208, 0);
}

.footer_logo{
    width: 185px;
    height: 105px;
}

.app_stores_footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 250px;
    width: 100%;
}

.stores_img{
    width: 120px;
}

.footer_social_flex{
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-width: 300px;
    width: 100%;
}

.footer_social_div{
    width: 35px;
    height: 35px;
    box-shadow: 0 0 3px #ccc;
    border-radius: 8px;
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer_heading{
    margin-top: 15px;
    font-size: 12px;
    color: grey;
}

@media (max-width: 782px) {
    .footer{
    margin-bottom: 60px;
    padding-top: 10px;
}
.footer_heading{
   text-align: center;
}
}