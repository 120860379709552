.navbar_div {
    position: fixed;
    z-index: 99999;
    display: flex;
    max-height: 70px;
    justify-content: space-evenly;
    width: 100%;
    background-color: rgba(0, 0, 0);
    top: 0;
}

.alert_div_row_flex {
    display: flex;
    justify-content: space-between;
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;
    margin: auto;
    align-items: center;
    border-bottom: 1px solid #ccc;
}

.alert_disabled {
    display: flex;
    justify-content: space-between;
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;
    margin: auto;
    align-items: center;
    border-bottom: 1px solid #ccc;
    background-color: rgb(245, 245, 245);
    cursor: not-allowed;
}

.alert_text_heading_disabled {
    color: #ccc;
    font-size: 18px;
    font-weight: 600;
    width: 100% !important;
}

.alert_disabled_img {
    filter: grayscale(100%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 0 10px #ccc;
}

.alert_text_subheading_disabled {
    color: #ccc;
    font-size: 14px;
    width: 100% !important;
}

.alert_div_row_flex_icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 0 10px #ccc;
    object-fit: contain;
}

.nav_icon {
    width: 25px;
    height: 25px;
}

.navbar_logo_div {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar_logo {
    width: 15.76%;
    margin-right: 5px;
}

.navbar_logo_name {
    width: 54.24%;
    margin-left: 30px;
}

.icon_link {
    cursor: pointer;
}

.navlinks_link_div {
    width: 100%;
    margin: 1%;
    color: white;
}

.navlinks_link_div_head {
    color: white;
    font-weight: 500;
    font-size: 0.65rem;
    padding: 0;
    margin: 0;
}


.navlinks_link_div_head:hover {
    color: rgb(255, 208, 0);
}

.navlinks_link_div_head_active {
    color: rgb(255, 208, 0);
    font-weight: 500;
    font-size: 0.65rem;
    padding: 0;
    margin: 0;
}

.navlinks_link {
    text-decoration: none;
}

.navlinks_link_black {
    text-decoration: none;
    color: black;
}

.navbar_div_navbar {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
}

.navbar_div_navbar_icons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 20%;
}

.navbar_div ul li {
    text-decoration: none;
    list-style: none;
    padding: 0 1% 0;
}

.login_avatar_div {
    display: flex;
    align-items: center;
}

.navbar_popper {
    width: 100%;
    position: fixed;
    right: 5%;
}

.navbar_popper_triangle {
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    min-height: 20px;
    min-width: 23px;
    position: absolute;
    z-index: 99999999999;
    background-color: white;
    opacity: 0;
    transition: all .4s ease-in-out;
}

.navbar_popper_div {
    background-color: white;
    position: absolute;
    z-index: 99999999;
    width: 350px;
    right: 0;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;
    border-radius: 8px;
    margin-top: 19px;
    transition: all 0.5s ease-in-out;
    overflow-y: scroll;
    text-align: center;
    padding: 10px 0px 10px;
}

.navbar_popper_div_mr {
    background-color: white;
    position: absolute;
    z-index: 99999999;
    width: 350px;
    right: 0;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-right: 30px;
    border-radius: 8px;
    margin-top: 19px;
    transition: all 0.5s ease-in-out;
    overflow-y: scroll;
    text-align: center;
    padding: 10px 0px 10px;
}

.navbar_popper_div_center {
    background-color: white;
    position: absolute;
    z-index: 99999999;
    width: 350px;
    right: 0;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
    border-radius: 8px;
    margin-top: 19px;
    transition: all 0.5s ease-in-out;
    overflow-y: scroll;
    text-align: center;
}

.heart_div_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    max-height: 50px;
    margin: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: .5px solid #ccc;
    position: relative;
    z-index: 99999;
}

.heart_div_row_disabled {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    padding-left: 2.5%;
    padding-right: 2.5%;
    max-height: 50px;
    margin: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: .5px solid #ccc;
    position: relative;
    z-index: 99999;
    background-color: rgb(245, 245, 245);
    cursor: not-allowed;
}

.align_heart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.heart_logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: contain;
}

.heart_logo_disabled {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    filter: grayscale(100%);
}

.heart_title {
    font-size: 18px;
    color: black;
    font-weight: 600;
    margin-left: 15px;
}

.heart_title_disabled {
    font-size: 18px;
    color: #ccc;
    font-weight: 600;
    margin-left: 15px;
}

.heart_subtitle {
    font-size: 14px;
    color: red;
}

.heart_subtitle_disabled {
    color: #ccc;
    font-size: 14px;
}

.alert_div_row {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 71%;
    text-align: left;
    min-height: 50px;
    margin: auto;
    margin-top: 2px;
    padding-bottom: 10px;
    margin-top: 10px;
}

.alert_logo {
    width: 50px;
    height: 50px;
}

.profile_logo {
    width: 25px;
    height: 25px;
}

.alert_title {
    font-size: 18px;
    color: orangered;
    font-weight: 600;
    width: 100% !important;
}

.alert_subtitle {
    font-size: 14px;
    color: black;
    width: 100%;
}

.profile_div_row {
    display: flex;
    align-items: center;
    width: 95%;
    max-height: 50px;
    margin: auto;
    cursor: pointer;
    margin-top: 2px;
    padding-bottom: 10px;
    margin-top: 10px;
    border-bottom: .5px solid #ccc;
}

.profile_title {
    margin-left: 15px;
    color: rgb(68, 68, 68);
        white-space: nowrap;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
}

@media (max-width:1150px) {
    .navbar_logo_div {
        width: 25%;
    }

    .navlinks_link_div_head {
        font-size: 0.65rem;
    }

    .navlinks_link_div_head_active {
        font-size: 0.65rem;
    }

    .navbar_div_navbar {
        width: 55%;
    }
}

@media (max-width:1050px) {

    .navlinks_link_div_head {
        font-size: 0.60rem;
    }

    .navlinks_link_div_head_active {
        font-size: 0.60rem;
    }
}

@media (max-width:927px) {
    .navlinks_link_div_head {
        font-size: 0.50rem;
    }

    .navlinks_link_div_head_active {
        font-size: 0.50rem;
    }
}

@media (max-width:788px) {
    .navlinks_link_div_head {
        font-size: 0.40rem;
    }

    .navlinks_link_div_head_active {
        font-size: 0.40rem;
    }
}

@media (max-width:993px) {
    .navbar_div_navbar .navlinks_link_div_head .navlinks_link_div_head_active {
        font-size: 9px;
    }
}

@media (max-width: 782px) {
    .navbar_div {
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;
    }

    .navbar_div_navbar {
        display: none;
    }

    .navbar_div_navbar_icons {
        width: 50%;
    }

    .navbar_logo_div {
        width: 100%;
    }
}



@media (max-width: 500px) {
    .navbar_logo_name {
        width: 93.24%;
        margin-left: 0px;
    }

    .navbar_div_navbar_icons {
        width: 59%;
    }

    .navbar_logo_div {
        width: 68%;
    }
}

@media (max-width: 375px) {
    .navbar_popper_div_center {
        width: 100%;
        margin-right: 0px;
    }

    .navbar_popper_div_mr {
        width: 100%;
        margin-right: 0px;
    }

}