.open_in_app_btn{
    width: 100%;
    max-width: 400px;
    border-radius: 15px;
    height: 52px;
    box-shadow: 0 0 10px #ccc;
    color: #fff;
    background-color: #FAA61C;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.open_in_app_btn:hover{
    background-color: #c97e05;
}

.open_in_browser_btn{
    width: 100%;
    max-width: 400px;
    border-radius: 15px;
    height: 52px;
    box-shadow: 0 0 10px #ccc;
    color: #FAA61C;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 15px;
}


.open_in_browser_btn:hover{
    background-color: rgb(241, 241, 241);
}

.logo_modal{
    width: 50%;
}